import { types, flow, SnapshotOut } from "mobx-state-tree";
import { api } from "@/api";
import { v4 as uuidv4 } from "uuid";
const AssistantItem = types.model("AssistantItem", {
  name: types.string,
  description: types.maybeNull(types.string),
  instructions: types.maybeNull(types.string),
  model: types.string,
  created_at: types.number,
  tools: types.array(types.model({ type: types.string })),
  metadata: types.frozen(),
  id: types.optional(types.string, ""),
  file_ids: types.maybeNull(types.optional(types.array(types.string), [])),
});

export type IAssistantItem = SnapshotOut<typeof AssistantItem>;

const _Assistant = types
  .model("Assistant", {
    currentAssistantId: types.maybeNull(types.string),
    list: types.array(AssistantItem),
    models: types.array(types.string),
    clone: types.boolean,
    edit: types.boolean,
    newFile: types.number,
    intentionList: types.frozen()
  })
  .views((self) => ({
    get assistantMap() {
      return self.list.reduce((acc, a) => {
        acc[a.id] = a;
        return acc;
      }, {} as Record<string, IAssistantItem>);
    },
  }))
  .views((self) => ({
    get currentAssistant() {
      return self.currentAssistantId
        ? self.assistantMap[self.currentAssistantId]
        : null;
    },
  }))
  .actions((self) => ({
    setCurrentAssistantId(id: string) {
      self.currentAssistantId = id;
    },
    setClone(val: boolean) {
      self.clone = val;
    },
    setEdit(val: boolean) {
      self.edit = val;
    },
    setNewFile() {
      self.newFile += 1;
    },
  }));

export const Assistant = _Assistant.actions((self) => {
  const setList = (list: IAssistantItem[]) => {
    self.list.replace(list as any);
  };
  const setIntentionList = (list: any) => {
    self.intentionList = list;
  };
  const loadAsistants = flow(function* () {
    const asis: ResolvePromiseReturnType<typeof api.getAssistants> =
      yield api.getAssistants();

    asis.data.forEach((a) => {
      if (!a.name) {
        a.name = a.id;
      }
      if (!a.metadata) {
        a.metadata = {};
      }
    });
    setList(asis.data);
    return asis.data;
  });

  /** 加载模型列表*/
  const loadModels = flow(function* () {
    const models: ResolvePromiseReturnType<typeof api.getModelList> =
      yield api.getModelList();
    const list = models.data.map((item) => item.id);
    self.models.replace(list);
  });
  // 生成意图目录结构
  const intentDir = (data: any) => {
    let intents: any = [];
    if (data.length > 0) {
      const metaData = data[0].meta_data;
      const acts = metaData.acts;
      const actArgMap = Object.fromEntries(
        metaData.act_args.map((actArg) => [actArg.hasName, actArg])
      );
      const entityMap = Object.fromEntries(
        metaData.entities.map((entity) => [entity.hasName, entity])
      );
      // 动作#实体#属性#维度
      for (const act of acts) {
        const intent: any = { act, entities: [], entities2: [] };
        for (const argName of act.hasArgs) {
          const actArg = actArgMap[argName];
          if (actArg) {
            for (const hasValueObj of actArg.hasValue) {
              const entity: any = entityMap[hasValueObj.hasName];
              const entity2: any = actArgMap[argName]?.hasValue;
              if (entity) {
                intent.entities.push(entity);
              }
              if (entity2 && !intent.entities2?.length) {
                intent.entities2 = intent.entities2.concat(entity2);
              }
            }
          }
        }
        intents.push(intent);
      }
    }
    intents = intents.map((item: any) => {
      return {
        hasName: item.act.hasName,
        hasAttrs: item.entities?.length > 0 ? item.entities : item.entities2,
      };
    });
    intents = JSON.parse(
      JSON.stringify(intents).replaceAll(`"hasQualify":`, `"hasAttrs":`)
    );
    return traverse(intents);
  };
  const traverse = (arr, level = 1, fatherName = "", attrName = "") => {
    if (!arr) return;
    arr.forEach((item, index) => {
      item.level = level;
      item.fatherName = fatherName;
      item.attrName = attrName;
      item.key = fatherName + attrName + level + "-" + index;
      if (item.hasAttrs) {
        if (level === 2) {
          traverse(item.hasAttrs, level + 1, item.hasName, fatherName); // 递归遍历子数组
        } else {
          traverse(item.hasAttrs, level + 1, item.hasName, attrName); // 递归遍历子数组
        }
      }
      if (item.hasRange) {
        item.hasAttrs = (item.hasAttrs ?? []).concat(
          item.hasRange.map((item) => {
            return {
              hasName: item,
              unit: attrName && attrName === "属性条件搜索" ? "=" : ""
            };
          })
        );
      }
      return {
        ...item,
        level,
        fatherName,
        attrName,
        hasAttrs: item.hasAttrs,
        key: fatherName + attrName + level + "-" + index,
      };
    });
    return arr;
  };
  const formatData = (arr: any[], allData: any[], tier = 2) => {
    return arr.map((item) => {
      const hasAttrs = Object.keys(item?.hasAttrs ?? {})
        .map((key) => {
          const data = allData.find((aItem) => aItem.code === key) ?? {};
          const obj = {
            ...data,
            add_at: item?.hasAttrs[key]?.add_at ?? 1,
          };
          const cItem = formatData(
            Object.keys(data).length > 0 ? [obj] : [],
            allData,
            tier + 1
          );
          return cItem;
        })
        .flat()
        .sort((a, b) => a.add_at - b.add_at);
      return {
        ...item,
        key: uuidv4(),
        hasAttrs: hasAttrs,
        childrenObj: item.hasAttrs,
        tier,
        add_at: item?.add_at ?? 1,
      };
    });
  }
  const searchAll = flow(function* (data: {
    search: string;
    assistant_id: string;
    run_id: string;
    thread_id: string;
    message_id: string;
    conversation_id: string;
    communication_id: string;
  }) {
    return yield api.search_all(data);
  });

  const addAgentium = flow(function* (data: {
    version;
    name;
    user_id;
    assistant_id;
    meta_data;
    id;
  }) {
    return yield api.addAgentium(data);
  });

  const updateAgentiumName = flow(function* (data: { id; name }) {
    return yield api.updateAgentiumName(data);
  });

  const getAgentium = flow(function* ({ page_num, page_size, assistant_id }) {
    return yield api.getAgentium({ page_num, page_size, assistant_id });
  });

  const addMyqameta = flow(function* (data: {
    version;
    name;
    user_id;
    assistant_id;
    meta_data;
    id;
  }) {
    return yield api.addMyqameta(data);
  });

  const updateMyqametaName = flow(function* (data: { id; name }) {
    return yield api.updateMyqametaName(data);
  });

  const getMyqameta = flow(function* ({ page_num, page_size, assistant_id }) {
    return yield api.getMyqameta({ page_num, page_size, assistant_id });
  });

  const getTarget = flow(function* ({ user_id, assistant_id, date_str }) {
    return yield api.getTarget({ user_id, assistant_id, date_str });
  });

  const getCustomerData = flow(function* (data: { date_str, assistant_id }) {
    return yield api.getCustomerData(data);
  });
  const getAgentPackage = flow(function* (data: { plat_form, assistant_id, version }) {
    return yield api.getAgentPackage(data);
  });

  const addIntention = flow(function* (data: {
    version;
    name;
    user_id;
    assistant_id;
    meta_data;
    id;
  }) {
    return yield api.addIntention(data);
  });

  const updateIntentionName = flow(function* (data: { id; name }) {
    return yield api.updateIntentionName(data);
  });

  const getIntention = flow(function* ({ page_num, page_size, assistant_id }) {
    const res: any = yield api.getIntention({ page_num, page_size, assistant_id })
    // console.log(res, res?.data?.data, res?.data?.data ? intentDir(res?.data?.data) : [])
    setIntentionList(res?.data?.data ? intentDir(res?.data?.data) : [])
    console.log(formatData(intentDir(res?.data?.data), intentDir(res?.data?.data)))
    return res;
  });
  const getIntentionTree = flow(function* ({ page_num, page_size, assistant_id }) {
    return yield api.getIntentionTree({ page_num, page_size, assistant_id });
  });
  const updateIntentionActs = flow(function* ({ id, assistant_id, data }) {
    return yield api.updateIntentionActs({ id, assistant_id, data });
  });
  const updateIntentionEntities = flow(function* ({ id, assistant_id, data }) {
    return yield api.updateIntentionEntities({ id, assistant_id, data });
  });
  const updateIntentionAttr = flow(function* ({ id, assistant_id, data }) {
    return yield api.updateIntentionAttr({ id, assistant_id, data });
  });
  const deleteIntentionAttr = flow(function* ({ id, assistant_id, data }) {
    return yield api.deleteIntentionAttr({ id, assistant_id, data });
  });
  const addIntentionAttr = flow(function* ({ id, assistant_id, data }) {
    return yield api.addIntentionAttr({ id, assistant_id, data });
  });
  const updateIntentionQualify = flow(function* ({ id, assistant_id, data }) {
    return yield api.updateIntentionQualify({ id, assistant_id, data });
  });
  const deleteIntentionQualify = flow(function* ({ id, assistant_id, data }) {
    return yield api.deleteIntentionQualify({ id, assistant_id, data });
  });
  const addIntentionQualify = flow(function* ({ id, assistant_id, data }) {
    return yield api.addIntentionQualify({ id, assistant_id, data });
  });

  return {
    setList: setList,
    create: flow(function* (body: IAssistantItem) {
      const data = yield api.createAssistant(body);
      return data;
    }),
    update: flow(function* (body: Partial<IAssistantItem>) {
      yield api.updateAssistant(body);
    }),
    delete: flow(function* (id: string) {
      yield api.deleteAssistant(id);
      loadAsistants();
    }),
    load: loadAsistants,
    loadModels,
    searchAll,
    addAgentium,
    updateAgentiumName,
    getAgentium,
    addMyqameta,
    updateMyqametaName,
    getMyqameta,
    getTarget,
    getCustomerData,
    getAgentPackage,
    addIntention,
    updateIntentionName,
    getIntention,
    getIntentionTree,
    updateIntentionActs,
    updateIntentionEntities,
    updateIntentionAttr,
    deleteIntentionAttr,
    addIntentionAttr,
    updateIntentionQualify,
    deleteIntentionQualify,
    addIntentionQualify
  };
});
