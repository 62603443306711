import { useMemo, ReactNode, memo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Tooltip } from "antd";
interface SvgIconProps {
  prefix?: string;
  tip?: string | ReactNode;
  name: string;
  color?: string;
  size?: number | string;
  className?: any;
  onClick?: (e) => void;
}

const SvgIcon = memo(observer((props: SvgIconProps) => {
  const {
    prefix = "icon",
    name,
    color = "",
    size = 16,
    className = "",
    tip = "",
    onClick = (e: any) => {
      e.preventDefault();
    },
  } = props;
  const [hoverStatus, setHoverStatus] = useState(false)
  const symbolId = useMemo(() => `#${prefix}-${name}`, [prefix, name]);
  return (
    <div className="icon_gray_bg" onMouseEnter={() => setHoverStatus(true)} onMouseLeave={() => setHoverStatus(false)}>
      {className?.includes("iconBg") && (
        <>
          {
            hoverStatus ? <Tooltip title={tip}>
              <div className="icon_gray"></div>
            </Tooltip> : <div className="icon_gray"></div>
          }
        </>

      )}
      {
        hoverStatus ? <Tooltip title={tip}>
          <svg
            aria-hidden="true"
            className={className}
            width={size}
            height={size}
            fill={color}
            onClick={onClick}
            style={{ color, zIndex: 9999 }}
          >
            <use href={symbolId} fill={color} />
          </svg>
        </Tooltip> : <svg
          aria-hidden="true"
          className={className}
          width={size}
          height={size}
          fill={color}
          onClick={onClick}
          style={{ color, zIndex: 9999 }}
        >
          <use href={symbolId} fill={color} />
        </svg>
      }

    </div>
  );
}));
export default SvgIcon;
